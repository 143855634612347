'use client';

import * as Sentry from '@sentry/nextjs';
import { createStore, Provider } from 'jotai';
import { useHydrateAtoms } from 'jotai/react/utils';
import _ from 'lodash';
import { useRef } from 'react';
import {
  BannerCarouselFragmentFragment,
  ListItemFragmentFragment,
} from 'src/api/contentful/gen/graphql';
import { AllSports, AllTournaments } from 'src/api/left-nav';
import { _categoriesAtom, _favGamesAtom } from 'src/store/casino';
import { _userCountryAtom, _userPrefAtom } from 'src/store/layout';
import { _sportAtom } from 'src/store/sport';
import { _balancesAtom } from 'src/store/wallet';
import { UserPrefer } from 'src/utils/constants/common-constant';
import { isUnauthorizedError, MyError } from 'src/utils/helpers/my-error';
import {
  IUpdateAccountLevel,
  KYCStatusKey,
  ProfileData,
} from 'src/utils/types/auth';
import { CasinoCategory, CasinoGame } from 'src/utils/types/casino';
import { ListBalanceUserOwnedItem } from 'src/utils/types/wallet-type';
import { SWRConfig } from 'swr';
import {
  _accessTokenAtom,
  _accountInfoAtom,
  _fingerprintAtom,
  _kycAtom,
  _localeAtom,
  _profileAtom,
  UserFingerprint,
} from './auth';
import {
  bannerCarouselAtom,
  legalsAtom,
  logoAtom,
  socialMediasAtom,
} from './contentful';

export interface JotaiProviderProps {
  lang: string;
  jwt: string | null;
  profile: ProfileData | null;
  accInfo: IUpdateAccountLevel | null;
  kyc: KYCStatusKey;
  balances: ListBalanceUserOwnedItem[];
  sports: AllSports;
  tournaments: AllTournaments;
  favSports: AllSports;
  favTournaments: AllTournaments;
  categories: CasinoCategory[];
  favGames: CasinoGame[];
  legals: ListItemFragmentFragment[];
  socialMedias: ListItemFragmentFragment[];
  logo: string | null;
  banners: Record<string, BannerCarouselFragmentFragment[]>;
  fp: UserFingerprint;
  userPref: UserPrefer;

  userCountry?: string | null;
}

export const JotaiProvider: React.FCC<JotaiProviderProps> = (props) => {
  const {
    children,
    jwt,
    lang,
    profile,
    legals,
    socialMedias,
    logo,
    banners,
    fp,
    userPref,
    accInfo,
    balances,
    kyc,
    sports,
    tournaments,
    favSports,
    favTournaments,
    categories,
    favGames,
    userCountry,
  } = props;
  const store = useRef(createStore());

  useHydrateAtoms(
    _.compact([
      [_localeAtom, lang],
      [_accessTokenAtom, jwt],

      [_profileAtom, profile],
      [_accountInfoAtom, accInfo],
      [_kycAtom, kyc],
      [_balancesAtom, balances],
      [
        _sportAtom,
        {
          sports,
          tournaments,
          favSports,
          favTournaments,
        },
      ],

      [_categoriesAtom, categories],
      [_favGamesAtom, favGames],

      logo && [logoAtom, logo],
      [legalsAtom, legals],
      [socialMediasAtom, socialMedias],
      [bannerCarouselAtom, banners],
      [_fingerprintAtom, fp],
      [_userPrefAtom, userPref],
      userCountry && [_userCountryAtom, userCountry],
    ]),
    { store: store.current },
  );

  return <Provider store={store.current}>{children}</Provider>;
};

export const SWRProvider: React.FCC<{ fallback: any }> = ({
  children,
  fallback,
}) => {
  return (
    <SWRConfig
      value={{
        fallback,
        onError: (err: MyError) => {
          if (!isUnauthorizedError(err)) {
            Sentry.captureException(err);
          }
        },
        refreshWhenOffline: false,
        refreshWhenHidden: false,
        refreshInterval: 0,
        keepPreviousData: true,
      }}>
      {children}
    </SWRConfig>
  );
};
